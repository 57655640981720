<template>
  <div class="hello">
    <div class="panel__top">
      <div class="panel__basic-actions"></div>
      <div class="panel__devices"></div>
      <div class="panel__switcher"></div>
    </div>
    <div class="editor-row">
      <div class="editor-canvas">
        <div id="gjs">...</div>
      </div>
      <div class="panel__right">
        <div class="layers-container"></div>
        <div class="styles-container"></div>
        <div class="traits-container"></div>
      </div>
    </div>
    <div id="blocks"></div>
    <b-row style="margin-left: 0; margin-right: 0; margin-top: 10px">
      <b-button :disabled="onSaveClick" @click="save()" variant="outline-primary" style="margin-left: 20px">
        <b-spinner v-if="onSaveClick" small label="Spinning"></b-spinner>
        Save
      </b-button>
      <b-button @click="download()" variant="outline-primary" style="margin-left: 20px">
<!--        <b-spinner small label="Spinning"></b-spinner>-->
        Download
      </b-button>
      <b-button :disabled="onPublishClick" @click="publish()" variant="outline-primary" style="margin-left: 20px">
        <b-spinner v-if="onPublishClick" small label="Spinning"></b-spinner>
        Publish
      </b-button>
    </b-row>
  </div>
</template>

<script>
const axios = require('axios');
import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';
import {db} from '../../util/db'
import grapesjspresetwebpage from 'grapesjs-preset-webpage';

export default {
  data() {
    return {
      editor: null,
      onSaveClick: false,
      onPublishClick: false
    }
  },
  methods: {
    async publish() {
      this.onPublishClick = true;
      const html = this.editor.getHtml();
      const css = this.editor.getCss();
      const finalHtml = this.getHeader() + html + this.getFooter();
      const site = 'test-123-8bbe8';
      const files = {
        'index.html': finalHtml,
        'style.css': css
      };
      const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/publish/${site}`, files);
      if (response) {
        console.log(response);
        this.onPublishClick = false;
      }
    },
    async download() {
      const html = this.editor.getHtml();
      const css = this.editor.getCss();
      const finalHtml = this.getHeader() + html + this.getFooter();
      await this.downloadFile(finalHtml, 'index.html');
      await this.downloadFile(css, 'style.css');
    },
    getHeader() {
      return '<!doctype html>\n' +
          '<html lang="en">\n' +
          '\n' +
          '<head>\n' +
          '    <!-- Required meta tags -->\n' +
          '    <meta charset="utf-8">\n' +
          '    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">\n' +
          '\n' +
          '    <!-- Bootstrap CSS -->\n' +
          '    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css" integrity="sha384-TX8t27EcRE3e/ihU7zmQxVncDAy5uIKz4rEkgIXeMed4M0jlfIDPvg6uqKI2xXr2" crossorigin="anonymous">\n' +
          '    <link rel="stylesheet" href="https://storage.googleapis.com/pyxal-files/css/animate.min.css">\n' +
          '    <link rel="stylesheet" href="https://storage.googleapis.com/pyxal-files/css/slick.css">\n' +
          '    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i">\n' +
          '    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">\n' +
          '\n' +
          '\n' +
          '    <title></title>\n' +
          '    <meta content="" name="description">\n' +
          '    <meta content="" name="keywords">\n' +
          '\n' +
          '    <!-- Favicons -->\n' +
          '    <link href="./favicon/favicon-32x32.png" rel="icon">\n' +
          '\n' +
          '    <!---css-->\n' +
          '    <link rel="stylesheet" href="./style.css">\n' +
          '\n' +
          '</head>\n' +
          '\n' +
          '<body>';
    },
    getFooter() {
      return '<script src="https://code.jquery.com/jquery-3.5.1.slim.js"><'+'/script>' +
          '<script src="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/js/bootstrap.bundle.min.js"><'+'/script>' +
          '<script src="https://storage.googleapis.com/pyxal-files/js/active.js"><'+'/script>' +
          '<script src="https://storage.googleapis.com/pyxal-files/js/jquery.slicknav.min.js"><'+'/script>' +
          '<script src="https://storage.googleapis.com/pyxal-files/js/owl.carousel.js"><'+'/script>' +
          '<script src="https://storage.googleapis.com/pyxal-files/js/popper.min.js"><'+'/script>' +
          '<script src="https://storage.googleapis.com/pyxal-files/js/slick.min.js"><'+'/script>' +
          '<script src="https://storage.googleapis.com/pyxal-files/js/tether.min.js"><'+'/script>' +
          '<script src="https://storage.googleapis.com/pyxal-files/js/wow-1.3.0.min.js"><'+'/script>' +
          '</body>\n' +
          '</html>\n'
    },
    async downloadFile(data, filename) {
      const blob = new Blob([data], {type: 'text/plain'})
      const e = document.createEvent('MouseEvents'),
          a = document.createElement('a');
      a.download = filename;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    async save() {
      this.onSaveClick = true;
      await this.editor.store();
      this.onSaveClick = false;
    },
    async saveInDB(html, css) {
      return new Promise((resolve, reject) => {
        const docRef = db.collection('site').doc('sEeaTaS8MfgSRA56Ab4I');
        docRef.set({html, css}, {merge: true})
            .then(function () {
              resolve(true);
            })
            .catch(function () {
              reject();
            });
      });
    },
    async getFromDB() {
      return new Promise((resolve) => {
        const docRef = db.collection('site').doc('sEeaTaS8MfgSRA56Ab4I');
        docRef.get()
            .then(snapshot => {
              resolve(snapshot.data());
            });
      });
    },
    async initGrapeJs() {
      this.editor = grapesjs.init({
        container: '#gjs',
        fromElement: true,
        height: '700px',
        width: 'auto',
        canvas: {
          styles: [
            'https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css',
            'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i',
            'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css',
            'https://storage.googleapis.com/pyxal-files/css/animate.min.css',
            'https://storage.googleapis.com/pyxal-files/css/slick.css'
          ],
          scripts: [
            'https://code.jquery.com/jquery-3.5.1.slim.js',
            'https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/js/bootstrap.bundle.min.js',
            'https://storage.googleapis.com/pyxal-files/js/active.js',
            'https://storage.googleapis.com/pyxal-files/js/jquery.slicknav.min.js',
            'https://storage.googleapis.com/pyxal-files/js/owl.carousel.js',
            'https://storage.googleapis.com/pyxal-files/js/popper.min.js',
            'https://storage.googleapis.com/pyxal-files/js/slick.min.js',
            'https://storage.googleapis.com/pyxal-files/js/tether.min.js',
            'https://storage.googleapis.com/pyxal-files/js/wow-1.3.0.min.js'
          ]
        },
        storageManager: {type: 'simple-storage'},
        panels: {
          defaults: [
            {
              id: 'layers',
              el: '.panel__right',
              resizable: {
                maxDim: 350,
                minDim: 200,
                tc: 0, // Top handler
                cl: 1, // Left handler
                cr: 0, // Right handler
                bc: 0, // Bottom handler
                // Being a flex child we need to change `flex-basis` property
                // instead of the `width` (default)
                keyWidth: 'flex-basis'
              }
            },
            {
              id: 'panel-switcher',
              el: '.panel__switcher',
              buttons: [
                {
                  id: 'show-layers',
                  active: true,
                  label: 'Layers',
                  command: 'show-layers',
                  // Once activated disable the possibility to turn it off
                  togglable: false,
                }, {
                  id: 'show-style',
                  active: true,
                  label: 'Styles',
                  command: 'show-styles',
                  togglable: false,
                },
                {
                  id: 'show-traits',
                  active: true,
                  label: 'Traits',
                  command: 'show-traits',
                  togglable: false,
                }
              ],
            },
            {
              id: 'panel-devices',
              el: '.panel__devices',
              buttons: [{
                id: 'device-desktop',
                label: 'D',
                command: 'set-device-desktop',
                active: true,
                togglable: false,
              }, {
                id: 'device-mobile',
                label: 'M',
                command: 'set-device-mobile',
                togglable: false,
              }],
            }
          ]
        },
        deviceManager: {
          devices: [{
            name: 'Desktop',
            width: '', // default size
          }, {
            name: 'Mobile',
            width: '320px', // this value will be used on canvas width
            widthMedia: '480px', // this value will be used in CSS @media
          }]
        },
        plugins: [grapesjspresetwebpage],
        pluginsOpts: {
          'gjs-preset-webpage': {
            // options
          }
        },
        blockManager: {
          appendTo: '#blocks',
          blocks: [
            {
              id: 'section',
              label: '<b>Section</b>',
              attributes: {class: 'gjs-block-section'},
              content: `
                <section>
                    <h1>This is a simple title</h1>
                    <div>This is just a Lorem text: Lorem ipsum dolor sit amet</div>
                </section>`,
            },
            {
              id: 'text',
              label: 'Text',
              content: '<div data-gjs-type="text">Insert your text here</div>',
            },
            {
              id: 'image',
              label: 'Image',
              select: true,
              content: {type: 'image'},
              activate: true,
            }
          ]
        },
        layerManager: {
          appendTo: '.layers-container'
        },
        selectorManager: {
          appendTo: '.styles-container'
        },
        styleManager: {
          appendTo: '.styles-container',
          sectors: [{
            name: 'Dimension',
            open: false,
            // Use built-in properties
            buildProps: ['width', 'min-height', 'padding'],
            // Use `properties` to define/override single property
            properties: [
              {
                // Type of the input,
                // options: integer | radio | select | color | slider | file | composite | stack
                type: 'integer',
                name: 'The width', // Label for the property
                property: 'width', // CSS property (if buildProps contains it will be extended)
                units: ['px', '%'], // Units, available only for 'integer' types
                defaults: 'auto', // Default value
                min: 0, // Min value, available only for 'integer' types
              }
            ]
          }, {
            name: 'Extra',
            open: false,
            buildProps: ['background-color', 'box-shadow', 'custom-prop'],
            properties: [
              {
                id: 'custom-prop',
                name: 'Custom Label',
                property: 'font-size',
                type: 'select',
                defaults: '32px',
                // List of options, available only for 'select' and 'radio'  types
                options: [
                  {value: '12px', name: 'Tiny'},
                  {value: '18px', name: 'Medium'},
                  {value: '32px', name: 'Big'},
                ],
              }
            ]
          }]
        },
        traitManager: {
          appendTo: '.traits-container',
        },
      });

      this.editor.Panels.addPanel({
        id: 'panel-top',
        el: '.panel__top',
      });

      this.editor.Panels.addPanel({
        id: 'basic-actions',
        el: '.panel__basic-actions',
        buttons: [
          {
            id: 'visibility',
            active: true, // active by default
            className: 'btn-toggle-borders',
            label: '<u>B</u>',
            command: 'sw-visibility', // Built-in command
          }, {
            id: 'export',
            className: 'btn-open-export',
            label: 'Exp',
            command: 'export-template',
            context: 'export-template', // For grouping context of buttons from the same panel
          }, {
            id: 'show-json',
            className: 'btn-show-json',
            label: 'JSON',
            context: 'show-json',
            command(editor) {
              editor.Modal.setTitle('Components JSON')
                  .setContent(`<textarea style="width:100%; height: 250px;">
            ${JSON.stringify(editor.getComponents())}
          </textarea>`)
                  .open();
            },
          }
        ],
      });

      this.editor.Commands.add('show-layers', {
        getRowEl(editor) {
          return editor.getContainer().closest('.editor-row');
        },
        getLayersEl(row) {
          return row.querySelector('.layers-container')
        },

        run(editor, sender) {
          const lmEl = this.getLayersEl(this.getRowEl(editor));
          lmEl.style.display = '';
          console.log(sender)
        },
        stop(editor, sender) {
          const lmEl = this.getLayersEl(this.getRowEl(editor));
          lmEl.style.display = 'none';
          console.log(sender)
        },
      });

      this.editor.Commands.add('show-styles', {
        getRowEl(editor) {
          return editor.getContainer().closest('.editor-row');
        },
        getStyleEl(row) {
          return row.querySelector('.styles-container')
        },

        run(editor, sender) {
          const smEl = this.getStyleEl(this.getRowEl(editor));
          smEl.style.display = '';
          console.log(sender)
        },
        stop(editor, sender) {
          const smEl = this.getStyleEl(this.getRowEl(editor));
          smEl.style.display = 'none';
          console.log(sender)
        },
      });

      this.editor.Commands.add('show-traits', {
        getTraitsEl(editor) {
          const row = editor.getContainer().closest('.editor-row');
          return row.querySelector('.traits-container');
        },
        run(editor, sender) {
          this.getTraitsEl(editor).style.display = '';
          console.log(sender)
        },
        stop(editor, sender) {
          this.getTraitsEl(editor).style.display = 'none';
          console.log(sender)
        },
      });

      this.editor.Commands.add('set-device-desktop', {
        run: editor => editor.setDevice('Desktop')
      });

      this.editor.Commands.add('set-device-mobile', {
        run: editor => editor.setDevice('Mobile')
      });

      const self = this;

      this.editor.StorageManager.add('simple-storage', {

        async load(keys, clb) {
          const result = {};
          const data = await self.getFromDB();
          result['gjs-html'] = data.html;
          result['gjs-css'] = data.css;
          clb(result);
        },

        async store(data, clb) {
          await self.saveInDB(data['gjs-html'], data['gjs-css']);
          clb();
        }
      });
    }
  },
  async mounted() {
    await this.initGrapeJs();
    await this.editor.load();
  }
}
</script>

<style>

#gjs {
  border: 3px solid #444;
}

.gjs-cv-canvas {
  top: 0;
  width: 100%;
  height: 100%;
}

.gjs-block {
  width: auto;
  height: auto;
  min-height: auto;
}

.panel__top {
  padding: 0;
  width: 100%;
  display: flex;
  position: initial;
  justify-content: center;
  justify-content: space-between;
}

.panel__basic-actions {
  position: initial;
}

.editor-row {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  height: 700px;
}

.editor-canvas {
  flex-grow: 1;
}

.panel__right {
  flex-basis: 230px;
  position: relative;
  overflow-y: auto;
}

.panel__switcher {
  position: initial;
}

.panel__devices {
  position: initial;
}

#gjs {
  border: none;
}

/* Theming */

/* Primary color for the background */
.gjs-one-bg {
  background-color: #78366a;
}

/* Secondary color for the text color */
.gjs-two-color {
  color: rgba(255, 255, 255, 0.7);
}

/* Tertiary color for the background */
.gjs-three-bg {
  background-color: #ec5896;
  color: white;
}

/* Quaternary color for the text color */
.gjs-four-color,
.gjs-four-color-h:hover {
  color: #ec5896;
}

.gjs-frame {
  padding-top: 40px;
}

</style>
