<template>
  <div>
    <Editor/>
  </div>
</template>

<script>
import Editor from '../components/grapejs/Editor'

export default {
  components: {
    Editor
  }
}
</script>
