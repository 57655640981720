import {firebaseConfig} from '../constants/config'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = firebaseApp.firestore();
export const auth = firebaseApp.auth();


const {Timestamp, GeoPoint} = firebase.firestore;
export {Timestamp, GeoPoint}
